import React, { useState } from 'react';
import { Wrapper } from './PageComponents';
import { Link } from 'react-router-dom';
import { MdMenu } from 'react-icons/md';
import { AiOutlineClose } from 'react-icons/ai';
import AppBar from '@material-ui/core/AppBar';
import Toolbar from '@material-ui/core/Toolbar';
import Box from '@material-ui/core/Box';
import Typography from '@material-ui/core/Typography';
import IconButton from '@material-ui/core/IconButton';
import { SwipeableDrawer, List, ListItem } from '@material-ui/core';
import useScrollTrigger from '@material-ui/core/useScrollTrigger';
import Slide from '@material-ui/core/Slide';
import { logout } from '../common'
import { routeList } from '../routes';

export function Header(props) {
    const [isOpen, toggleDrawer] = useState(false);

    let trigger = !useScrollTrigger();

    if (props.showHeader === true) {
        trigger = true;
    }

    return (
        <Slide direction="down" in={trigger}>
            <AppBar className="header-container" position="fixed">
                <Wrapper>
                    <Toolbar variant={'dense'} disableGutters={true} style={{ padding: 0 }}>
                        <Box display="flex" flexGrow={1}>
                            <IconButton onClick={() => toggleDrawer(true)} edge="start" color="inherit" aria-label="menu">
                                <MdMenu />
                            </IconButton>
                            <IconButton><Link style={{ color: 'white' }} to="/">Content Management System</Link></IconButton>
                        </Box>
                        <IconButton onClick={logout} style={{ color: 'white' }}>Log out</IconButton>
                    </Toolbar>
                </Wrapper>
                <SwipeableDrawer onOpen={() => toggleDrawer(true)} anchor={'left'} open={isOpen} onClose={() => toggleDrawer(false)}>
                    <div className="full-list" role="presentation" onClick={() => toggleDrawer(false)} onKeyDown={() => toggleDrawer(false)}>
                        <Wrapper>
                            <Toolbar variant={'dense'} disableGutters={true}>
                                <Typography style={{ flex: 1 }} variant="h6">Meny</Typography>
                                <IconButton onClick={() => toggleDrawer(false)} edge="end" color="inherit" aria-label="menu">
                                    <AiOutlineClose />
                                </IconButton>
                            </Toolbar>
                            <List dense={true}>
                                {
                                    routeList.map((route, i) => {
                                        if (route.text != undefined) {
                                            return <Link key={i} to={route.url}><ListItem button={true} disableGutters={true}>{route.text}</ListItem></Link>
                                        }
                                    })
                                }
                            </List>
                        </Wrapper>
                    </div>
                </SwipeableDrawer>
            </AppBar>
        </Slide>
    );
}