import { useState } from 'react';
import { HeaderlessPage } from '../components/PageComponents';
import { Button, Input, Form } from '../components/BasicComponents';
import { MessageBar } from '../components/DataComponents';
import { postRequest } from '../fetchers'
import { setAccessToken, setUserInfo } from '../common'

export function LoginPage(props) {
    const [email, setEmail] = useState(null);
    const [password, setPassword] = useState(null);
    const [response, setResponse] = useState(null);

    function handleLogin(result) {
        //const token = result.logged_in;
        //const loggedIn = token ? true : false;
        
        if (result.success === true) {
            //setAccessToken(token);
            setUserInfo(result);
            props.update(result.success);
        } else {
            setResponse(result);
        }
    }

    function sendCredentials() {
        postRequest('/api/login', { email: email, password: password }, result => handleLogin(result));
    }

    return (
        <HeaderlessPage>
            <div style={{ textAlign: "center" }}>
                <h1 style={{ margin: 0, padding: '10px 0' }}>Login</h1>
                <Form onSubmit={sendCredentials}>
                    <Input autoFocus={true} onChange={e => setEmail(e.target.value)} type="text" placeholder="E-mail" /> <br />
                    <Input onChange={e => setPassword(e.target.value)} type="password" placeholder="Password" /> <br />
                    <Button>Login</Button>
                </Form>
            </div>
            <MessageBar response={response} />
        </HeaderlessPage>
    );
}