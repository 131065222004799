import React, { useEffect, useState } from 'react';
import { getRequest, postRequest } from '../fetchers';
import { MiniLoadingIndicator, Textarea } from './BasicComponents';

const statusLoading = 'loading';
const statusPending = 'PENDING';

export function triggerCeleryTaskGet(endpoint, callback) {
    getRequest(endpoint, (response) => callback(response.id))
}

export function triggerCeleryTaskPost(endpoint, data, callback) {
    postRequest(endpoint, data, (response) => callback(response.id))
}

export function CeleryTaskStatus({ taskId, ...props }) {
    // Main component to use when trying to check the status of a task
    const [taskResult, setResult] = useState(null);
    const [taskLog, setLog] = useState('');
    const [taskStart, setStart] = useState(null);

    function handleResult(result) {
        console.log(result)
        if (result && result.result !== null) {
            setResult(result.result);
            setLog(result.log);
        }
    }

    function handleError(error) {
        console.log(error)
        setResult(false);
    }

    useEffect(() => {
        if (taskResult === statusLoading || taskResult === statusPending) {
            let timer;
            timer = setInterval(() => {
                getRequest('/tasks/' + taskId, (result) => handleResult(result), (error) => handleError(error))
            }, 1000);
            return () => clearInterval(timer);
        }
    });

    useEffect(() => {
        if (taskId != null) {
            setResult(statusLoading); // Set to loading
            setLog(''); // Reset log
            setStart(new Date().getSeconds())
        }
    }, [taskId])

    return (
        <CeleryBox status={taskResult} taskName={props.taskName} taskStart={taskStart} log={taskLog} />
    )
}

export function CeleryBox({ status, ...props }) {
    // Shows status of task and text output
    let text = 'inactive'
    switch (status) {
        case statusLoading:
            text = <>active <MiniLoadingIndicator /></>
            break;
        case false:
            text = 'failed'
            break;
        case true:
            text = 'successful'
            break;
        case null:
            text = 'inactive'
            break;
        default:
            text = 'inactive'
            break;
    }

    const container = <span>{text}</span>
    const taskName = props.taskName ? ' - ' + props.taskName : ''; 
    
    let taskDuration = ''
    if (status !== statusLoading && status !== null) {
        const secondsPassed = Math.abs(props.taskStart - new Date().getSeconds());
        const minutesPassed = secondsPassed / 60;
        taskDuration = `took ${secondsPassed} seconds (${Math.floor(minutesPassed*100)/100} minutes)`;
    } else if (status !== null) {
        taskDuration = 'measuring...';
    }

    const durationText = taskDuration !== '' ? ' - ' + taskDuration : '';

    return (
        <div>
            <h3>Task{taskName} - {container}{durationText}</h3>
            <Textarea autoScroll className="celery-box__textarea" rows="10" readOnly value="Not implemented" />
        </div>
    )
}