import React, { useEffect } from 'react';
import { useState } from 'react';
import PropTypes from 'prop-types';
import { reload } from '../common';
import { postRequest } from '../fetchers';
import { Button, Checkbox, Input, Select, ExpanderComponent } from './BasicComponents';
import { DeleteButton } from './DataComponents';
import { getRequest } from '../fetchers';

export function CategoryList({ data, ...props }) {
    if (data == null) {
        return <div>Category list loading...</div>;
    }

    return (
        <>
            {
                data.map((item, i) => {
                    return <CategoryItem key={i}>{item}</CategoryItem>
                })
            }
        </>
    );
}

export function CategoryItem({ ...props }) {
    const data = props.children;

    const baseClass = 'category-list__'

    const buttons = <CategoryButtons data={data} type={0} />;

    // When we have children
    if (data.children.length) {
        return (
            <div className={baseClass + 'parent'}>
                <ExpanderComponent expanderText={data.name} extraContent={buttons}>
                    <div className={baseClass + 'child-container'}>
                        {data.children.map((child, i) => {
                            return <SubCategoryItem key={i} data={child} />
                        })}
                    </div>
                </ExpanderComponent>
            </div>
        )
    }

    return (
        <div className={baseClass + 'parent'}>
            <span className={baseClass + 'parent-name'}>{data.name}</span> {buttons}
        </div>
    )
}

export function SubCategoryItem({ data, ...props }) {
    const baseClass = 'category-list__'

    //console.log(data);

    return (
        <div className={baseClass + 'child'}>
            <span className={baseClass + 'child-name'}>{data.name}</span>
            {<CategoryButtons data={data} type={1} />}
        </div>
    )
}

export function CategoryButtons({ data, type, ...props }) {
    function saveData(value) {
        postRequest('/api/save_category', { parent_id: data.id, type: type+1, name: value }, reload());
    }

    var buttons = [
        <AddButton onSubmit={saveData} buttonText="Add sub category" placeholder="New sub category name" />,
        <EditButton dataType={type} dataId={data.id} />,
        <DeleteButton dataType={type} dataId={data.id} />
    ]

    return (
        buttons.slice(type).map((button, i) => {
            return (
                <span key={i}>
                    {button}&nbsp;
                </span>
            )
        }
        )
    )
}

export function AddNewCategory({ ...props }) {
    function saveData(value) {
        postRequest('/api/save_category', { type: 0, name: value }, reload());
    }

    return (
        <>
            <AddButton onSubmit={saveData} dataType={0} buttonText="Add new category" placeholder="New category name" />
        </>
    )
}

function AddButton({ onSubmit, buttonText, placeholder, ...props }) {
    const [isEditable, setEditable] = useState(false);
    const [value, setValue] = useState(null);

    function add() {
        if (onSubmit) {
            onSubmit(value);
        }
        setEditable(false);
    }

    function cancel() {
        setEditable(false);
    }

    if (isEditable) {
        return (
            <span>
                <Input onChange={(e) => setValue(e.target.value)} autoFocus={true} onEscape={cancel} onEnter={add} placeholder={placeholder}></Input> <Button onClick={add}>Save</Button> <Button onClick={cancel}>Cancel</Button>
            </span>
        )
    } else {
        return (
            <Button onClick={() => setEditable(!isEditable)}>{buttonText}</Button>
        )
    }
}

function EditButton({ dataId, dataType, ...props }) {
    const [isEditable, setEditable] = useState(false);
    const [value, setValue] = useState(null);

    function update() {
        postRequest('/api/update_category', { id: dataId, type: dataType, name: value }, reload());
        setEditable(false);
    }

    function cancel() {
        setEditable(false);
    }

    if (isEditable) {
        return (
            <span>
                <Input onChange={(e) => setValue(e.target.value)} autoFocus={true} onEscape={cancel} onEnter={update} placeholder="New name"></Input> <Button onClick={update}>Update</Button> <Button onClick={cancel}>Cancel</Button>
            </span>
        )
    } else {
        return (
            <Button onClick={() => setEditable(!isEditable)}>Edit</Button>
        )
    }
}