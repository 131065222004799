import { useState, useEffect } from 'react';
import { Heading, PageStandard } from '../components/PageComponents.js';
import { getRequest, postRequest } from '../fetchers';
import { Button, Form, Input } from '../components/BasicComponents';
import { setPageTitle } from "../common.js";

export function RedisPage() {
    setPageTitle('Index management');

    const initialStatusText = 'Nothing is running';
    const [statusText, setStatusText] = useState(initialStatusText);
    const [keys, setKeys] = useState([]);

    function clearRedis() {
        setStatusText("Clearing Redis");
        getRequest('/api/clear_redis', () => setStatusText("Done, Redis cleared"));
    }

    function deleteKeys(event) {
        postRequest('/api/redis_delete_keys', { pattern: event.target[0].value });
    }

    function searchRedis(event) {
        postRequest('/api/search_redis', { query: event.target[0].value }, result => setKeys(result.data));
    }

    return (
        <PageStandard>
            <div>Redis management - {statusText}</div>
            <br /><br />
            <Button onClick={clearRedis}>Clear Redis</Button>
            <br /><br />

            <Heading>Delete keys</Heading>
            <Form onSubmit={deleteKeys}>
                <Input placeholder="Pattern" />
            </Form>

            <br />

            <Heading>Search keys</Heading>
            <Form onSubmit={searchRedis}>
                <Input placeholder="Pattern" />
            </Form>
            <br />
            <div style={{border: '1px solid black'}}>
                <br />
                {keys.map(function (item, i) {
                    return <span key={i}>{item}<br></br></span>
                })}
                <br />
            </div>
        </PageStandard>
    );
}