import { useState, useEffect } from 'react';
import { PageStandard, Heading } from '../components/PageComponents.js';
import { Tabs } from '../components/BasicComponents';
import { getRequest, postRequest } from '../fetchers';
import { setPageTitle, reload } from "../common.js";
import { CategoryMapping, SubCategoryMapping, MappingList, AutoMapping } from '../components/CategoryMappingComponents.js';

export function CategoryMappingPage() {
    setPageTitle('Category Mapping');

    const [staticCategories, setStaticCategories] = useState([]);
    const [mappedData, setMappedData] = useState([]);
    const [uncategorized, setUncategorized] = useState([]);

    useEffect(() => {
        getRequest('/api/get_static_categories', setStaticCategories);
        getRequest('/api/get_category_full_data', setMappedData);
        getRequest('/api/get_uncategorized_pairs', setUncategorized);
    }, [])

    const tabs = [
        {
            text: 'Category mapping',
            component: <><Heading>Category mapping</Heading>
                <CategoryMapping staticCategories={staticCategories} /></>
        },
        {
            text: 'Sub category mapping',
            component: <><Heading>Sub category mapping</Heading>
                <SubCategoryMapping staticCategories={staticCategories} uncategorized={uncategorized} /></>
        },
        {
            text: 'Mapping suggestions',
            component: <>
                <Heading>Mapping suggestions</Heading>
                <AutoMapping />
            </>
        },
        {
            text: 'Mapped data',
            component: <><Heading>Mapped data</Heading>
                <MappingList data={mappedData} /></>
        }
    ]

    return (
        <PageStandard>
            <Tabs tabs={tabs} />
        </PageStandard>
    );
}

/*
Map categories
*/