import { useEffect, useState } from 'react';
import { PageStandard, Heading, CenterContent } from '../components/PageComponents';
import { Button, Input, Form, ButtonForm, PasswordInput } from '../components/BasicComponents';
import { MessageBar } from '../components/DataComponents';
import { getRequest, postRequest } from '../fetchers'
import { reload, passwordMatches, passwordMinLength } from '../common'

export function UsersPage(props) {
    const [users, setUsers] = useState([]);
    const [response, setResponse] = useState(null);

    useEffect(() => {
        getRequest('/api/get_users', result => setUsers(result));
    }, []);

    function handleMessage(result) {
        if (result.success === true) {
            reload();
        } else {
            setResponse(result);
        }
    }

    function deleteUser(userId) {
        postRequest('/api/delete_user', { id: userId }, handleMessage)
    }

    function UserItem({ data }) {
        const { email, id } = data;

        function updatePassword(event, userId) {
            if (!passwordMinLength(event.target[0].value)) {
                setResponse({success: false, message: 'Password length has to be 8 symbols or more'});
                return;
            }

            if (!passwordMatches(event.target[0].value, event.target[1].value)) {
                setResponse({success: false, message: 'Password doesn\'t match'});
                return;
            }

            postRequest('/api/update_user_password', { id: userId, password: event.target[0].value }, handleMessage)
        }

        function updateEmail(event, userId) {
            postRequest('/api/update_user_email', { id: userId, email: event.target[0].value }, handleMessage)
        }

        const updateEmailButton = (
            <ButtonForm inline={true} buttonText="Edit e-mail" onSubmit={(e) => updateEmail(e, id)}>
                <Input autoFocus={true} type="text" placeholder="E-mail" />
            </ButtonForm>
        )

        const updatePasswordButton = (
            <ButtonForm inline={true} buttonText="Set password" onSubmit={e => updatePassword(e, id)}>
                <PasswordInput /> <PasswordInput placeholder="Confirm password" />
            </ButtonForm>
        )

        return <div>{email} {updatePasswordButton} {updateEmailButton} <Button onClick={() => deleteUser(id)}>Delete user</Button></div>
    }

    function handleSubmit(event) {
        let i = 0;
        let email = event.target[i++].value;
        let password = event.target[i++].value;
        let confirmPassword = event.target[i++].value;

        if (!passwordMinLength(password)) {
            setResponse({success: false, message: 'Password length has to be 8 symbols or more'});
            return;
        }

        if (!passwordMatches(password, confirmPassword)) {
            setResponse({success: false, message: 'Password doesn\'t match'});
            return;
        }

        const data = {
            email: email,
            password: password
        }

        postRequest('/api/create_user', data, handleMessage);
    }

    return (
        <PageStandard>
            <CenterContent>
                <Heading>Users</Heading>

                {users.map(function (user, i) {
                    return <UserItem key={i} data={user} />
                })}

                <hr />

                <ButtonForm buttonText="New user" onSubmit={e => handleSubmit(e)}>
                    <Input autoFocus={true} type="text" placeholder="E-mail" />
                    <br />
                    <PasswordInput />
                    <br />
                    <PasswordInput placeholder="Confirm password" />
                </ButtonForm>

                <MessageBar response={response} />
            </CenterContent>
        </PageStandard>
    );
}