import React from 'react';
import { useState, useEffect } from 'react';
import { Routes } from './routes';
import { LoginPage } from './pages/LoginPage';
import { getRequest } from './fetchers';
import './index.scss';

export function App() {
    const [authenticated, setAuth] = useState(null);
    
    useEffect(() => {
        getRequest('/api/check_auth', result => setAuth(result));
    }, []);

    useEffect(() => {
    }, [authenticated]);

    if (authenticated) {
        return (
            <Routes />
        );
    } else if(authenticated === false) {
        return (
            <LoginPage update={setAuth} />
        )
    } else {
        return 'Loading...';
    }
}
