import { Children, cloneElement } from "react";
import React, { useEffect, useState, useRef } from 'react';
import { getRequest } from '../fetchers';
import { cookieEnums } from '../common';
import { useCookies } from 'react-cookie'
import PropTypes from "prop-types";

export function Tabs({ tabs, ...props }) {
    const [cookies, setCookie] = useCookies([cookieEnums.activeTab])
    const preSelected = cookies.activeTab != null ? parseInt(cookies.activeTab) : 0;
    const [selected, setSelected] = useState(preSelected);

    const baseClass = 'component-tabs__';

    function onSelect(index) {
        setSelected(index);

        let expires = new Date()
        expires.setTime(expires.getTime() + (1000000000000))
        setCookie(cookieEnums.activeTab, index, { path: '/', expires })
    }

    return (
        <div className={baseClass + 'container'}>
            <div className={baseClass + 'tab-container'}>
                {tabs.map((tab, i) => {
                    const itemSelected = i === selected ? ' ' + baseClass + 'tab-item-selected' : '';
                    return (
                        <div key={i} className={baseClass + 'tab-item' + itemSelected} onClick={() => onSelect(i)}>
                            {tab.text}
                        </div>
                    )
                })}
            </div>

            {tabs.map((tab, i) => {
                const visible = i === selected ? ' ' + baseClass + 'component-visible' : '';
                return (
                    <div key={i} className={baseClass + 'component' + visible}>
                        {tab.component}
                    </div>
                )
            })}
        </div>
    )
}

export function PageLoadingIndicator({ ...props }) {
    return (
        <div className="loading-component__container">
            <div className="loading-component__indicator"></div>
            <div className="loading-component__text">{props.children}</div>
        </div>
    );
}

export function MiniLoadingIndicator({ ...props }) {
    return (
        <div className="mini-loading-component__container">
            <div className="mini-loading-component__indicator"></div>
        </div>
    );
}

export function ExpanderComponent(props) {
    const [isOpen, setOpen] = useState(props.open); // Keep track of open state
    const [data, setData] = useState(null); // Save data on open

    useEffect(() => {
        if (props.open === null) {
            setOpen(false);
        } else {
            setOpen(props.open);
        }
    }, [props.open])

    function handleClick() {
        setOpen(!isOpen);
        if (props.onClick) {
            props.onClick();
        }
    }

    function handleDataFetch() {
        props.loadData(setData);
    }

    // When it is open and we don't have any data
    useEffect(() => {
        if (isOpen === true && data === null && props.loadData) {
            handleDataFetch();
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [isOpen])

    var children = props.children;
    if (data !== null) {
        children = Children.map(children, (child) =>
            cloneElement(child, { data }) // Create child element and insert props
        )
    }

    return (
        <div>
            <Expander onClick={handleClick}><span className="expander__text-container">{props.expanderText}</span></Expander> {props.extraContent}
            <Expandable open={isOpen}>{children}</Expandable>
        </div>
    )
}

export function Expander(props) {
    return (
        <span style={{ cursor: 'pointer' }} onClick={props.onClick}>{props.children}</span>
    )
}

export function Expandable({ open, ...props }) {
    return (
        <div style={open === true ? { display: 'block' } : { display: 'none' }}>
            {props.children}
        </div>
    )
}

export function Button({ ...props }) {
    return (
        <button {...props}>{props.children}</button>
    )
}

export function Checkbox({checkboxTemplate, defaultChecked, ...props }) {
    const [checked, setChecked] = useState(defaultChecked);

    useEffect(() => {
        setChecked(defaultChecked);
    }, [defaultChecked])

    return (
        <label><input checked={checked} onChange={() => setChecked(!checked)} type="checkbox" {...props}></input>{checkboxTemplate()}</label>
    )
}

export function Form({ ...props }) {
    function handleSubmit(event) {
        event.preventDefault();

        if (props.onSubmit) {
            props.onSubmit(event);
        }
    }

    return (
        <form {...props} onSubmit={e => handleSubmit(e)}>
            {props.children}
        </form>
    )
}

export function Input({ onEnter, onEscape, ...props }) {
    const ref = useRef(null);
    const [value, setValue] = useState(null);
    const [focus, setFocus] = useState(props.autoFocus);

    function handleKeyDown(e) {
        if (e.key === 'Enter' && onEnter) {
            onEnter();
        } else if (e.key === 'Escape') {
            if (onEscape) {
                onEscape();
            } else {
                setValue('');
            }
        }
    }

    function handleChange(e) {
        if (props.onChange) {
            props.onChange(e);
        } else {
            setValue(e.target.value);
        }
    }

    useEffect(() => {
        if (focus) {
            ref.current.focus();
            setFocus(false);
        }
    })

    return (
        <input {...props} ref={ref} onChange={(e) => handleChange(e)} onKeyDown={(e) => handleKeyDown(e)}></input>
    )
}

export function Select({ onChange, ...props }) {
    const [value, setValue] = useState(props.value);

    function handleChange(e) {
        setValue(e.target.value);
        if (onChange) {
            onChange(e);
        }
    }

    useEffect(() => {
        setValue(props.value);
    }, [props.value])

    return (
        <select {...props} value={value} onChange={(e) => handleChange(e)} />
    )
}

export function ButtonForm({ ...props }) {
    const [clicked, setClicked] = useState(false);

    function updateClicked() {
        setClicked(!clicked);
    }

    const inlineStyle = props.inline ? { display: 'inline-block' } : {}

    const elements = (
        <Form onSubmit={(e) => { props.onSubmit(e); updateClicked() }} style={inlineStyle}>
            {props.children}
            <div style={inlineStyle}>
                <Button>Update</Button> <Button onClick={updateClicked}>Cancel</Button>
            </div>
        </Form>
    )

    if (clicked) {
        return (
            elements
        );
    }

    return (
        <Button onClick={updateClicked}>{props.buttonText}</Button>
    )
}

ButtonForm.propTypes = {
    onSubmit: PropTypes.func.isRequired,
    buttonText: PropTypes.string.isRequired,
    inline: PropTypes.bool
};

export function Textarea({ autoScroll, ...props }) {
    const ref = useRef(null);

    useEffect(() => {
        if (autoScroll) {
            ref.current.scrollTop = ref.current.scrollHeight;
        }
    }, [props.value]);

    return (
        <textarea ref={ref} {...props}></textarea>
    )
}

export function PasswordInput({ ...props }) {
    return (
        <Input placeholder="Password" autoComplete="new-password" type="password" {...props} />
    )
}

export function ProgressBar({ value, ...props }) {
    return (
        <div>
            <progress value={value} max={props.max} />
            <span className="progress-text">{(value / props.max) * 100}%</span>
        </div>
    )
}

ProgressBar.propTypes = {
    value: PropTypes.number.isRequired,
    buttonText: PropTypes.string
};

ProgressBar.defaultProps = {
    max: 100
};