import { PageStaticContent } from '../components/PageComponents';
import {
    Link
} from "react-router-dom";

export function OopsPage() {
    return (
        <PageStaticContent>
            <div style={{ textAlign: "center" }}>
                <h1 style={{ margin: 0, padding: '10px 0' }}>Oops!</h1>
                <div>Return to <Link style={{ textDecoration: "underline" }} to="/">home</Link>.</div>
            </div>
        </PageStaticContent>
    );
}