import { useState } from 'react';
import { PageStandard } from '../components/PageComponents';
import { Button, Input, Form, PasswordInput } from '../components/BasicComponents';
import { MessageBar } from '../components/DataComponents';
import { postRequest } from '../fetchers'
import { getUserInfo, validateEmail, passwordMatches, passwordMinLength, reload, setUserInfo } from '../common'

export function ProfilePage(props) {
    const [response, setResponse] = useState(null);

    function updateEmail(event) {
        function handleResponse(result) {
            setUserInfo(result);
            reload();
        }

        const newEmail = event.target[0].value;
        const emailCheck = validateEmail(newEmail);

        if (emailCheck === false) {
            setResponse({ success: false, message: 'E-mail doesn\'t have a valid format' });
            return;
        }

        postRequest('/api/update_user_email', { email: newEmail }, handleResponse);
    }

    function updatePassword(event) {
        const newPassword = event.target[0].value;
        const confirmPassword = event.target[1].value;

        if (!passwordMinLength(newPassword)) {
            setResponse({ success: false, message: 'Password length has to be 8 symbols or more' });
            return;
        }

        if (!passwordMatches(newPassword, confirmPassword)) {
            setResponse({ success: false, message: 'Password doesn\'t match' });
            return;
        }

        postRequest('/api/update_user_password', { password: newPassword });
    }

    return (
        <PageStandard>
            <div style={{ textAlign: "center" }}>
                <h1 style={{ margin: 0, padding: '10px 0' }}>Profile - {getUserInfo('email')}</h1>

                <Form onSubmit={(e) => updateEmail(e)}>
                    <Input placeholder="New e-mail" /> <Button>Update e-mail</Button>
                </Form>

                <br />

                <Form onSubmit={(e) => updatePassword(e)}>
                    <PasswordInput placeholder="New password" /> <br />
                    <PasswordInput placeholder="Confirm new password" /> <br />
                    <Button>Update password</Button>
                </Form>
            </div>

            <MessageBar response={response} />
        </PageStandard>
    );
}