import { useState, useEffect } from 'react';
import { PageStandard, Heading } from '../components/PageComponents.js';
import { getRequest, postRequest } from '../fetchers';
import { Button, Select, Checkbox } from '../components/BasicComponents';
import { CeleryTaskStatus, triggerCeleryTaskGet, triggerCeleryTaskPost } from '../components/CeleryComponents';
import { setPageTitle } from "../common.js";

export function CiPage() {
    setPageTitle('CI');

    const [taskName, setTaskName] = useState(null);
    const [taskId, setTask] = useState(null);
    const [skipDownload, setDownload] = useState(false);
    const [updateAlgolia, setAlgolia] = useState(false);
    const [datafeed, setDatafeed] = useState('');

    function startTaskGet(endpoint, name = null) {
        setTaskName(name);
        triggerCeleryTaskGet(endpoint, setTask);
    }

    function startTaskPost(endpoint, data, name = null) {
        setTaskName(name);
        triggerCeleryTaskPost(endpoint, data, setTask);
    }

    // API AND FRONTEND
    function updateApi() {
        startTaskGet('/api/update_api', 'Update API');
    }

    function updateFrontend() {
        startTaskGet('/api/update_frontend', 'Update frontend');
    }

    function updateCMSFrontend() {
        startTaskGet('/api/update_cms_frontend', 'Update CMS frontend');
    }

    // INDICES
    function updateIndicesCompletely() {
        startTaskGet('/api/update_indices_completely', 'Update every aspect of indices')
    }

    function updateArticles() {
        startTaskGet('/api/update_articles', 'Update articles');
    }

    function updateIndices() {
        startTaskPost('/api/update_indices', { update_algolia: updateAlgolia } , 'Update index only');
    }

    function updateNavigation() {
        startTaskGet('/api/update_navigation', 'Update navigation');
    }

    function updateAffiliateLinks() {
        startTaskPost('/api/update_affiliate_links', { option: datafeed, skip_download: skipDownload }, 'Update affiliate links');
    }

    function testAsync() {
        startTaskGet('/test', 'Testing');
    }

    const affiliate = [
        { value: '', text: 'Choose store' },
        { value: 'asos', text: 'ASOS' },
        { value: 'boohoo', text: 'boohoo' },
        { value: 'bubbleroom', text: 'Bubbleroom' },
        { value: 'all', text: 'All' }
    ]

    const affiliateOptions = affiliate.map(({ value, text }) => <option key={value} value={value}>{text}</option>);

    return (
        <PageStandard>
            <Heading>CI - Index, API and frontend management</Heading>

            <p>Server and frontend</p>
            <Button onClick={updateApi}>Update API</Button>
            <br />
            <Button onClick={updateFrontend}>Update frontend</Button>
            <br />
            <Button onClick={updateCMSFrontend}>Update CMS frontend</Button>

            <p>Articles and indices</p>
            <p>
                1. Update articles - Get latest articles and process them.<br />
                2. Update affiliate links - Update the articles with affiliate links.<br />
                3. Update indices - Update the indices.<br />
            </p>
            <Button onClick={updateArticles}>Update articles</Button> <br />
            <Button onClick={updateAffiliateLinks}>Update affiliate links</Button> <Select onChange={(e) => setDatafeed(e.target.value)}>{affiliateOptions}</Select> <Checkbox defaultChecked={skipDownload} onChange={() => setDownload(!skipDownload)} checkboxTemplate={() => {return 'Skip downlod'}} /><br />
            <Button onClick={updateIndices}>Update indices</Button> <Checkbox defaultChecked={updateAlgolia} onChange={() => setAlgolia(!updateAlgolia)} checkboxTemplate={() => {return 'Update Algolia'}} /><br />
            
            <br />
            <Button onClick={updateIndicesCompletely}><strong>Complete update in one go</strong></Button> <br />

            <p>Async testing</p>
            <Button onClick={testAsync}>Async</Button>

            <CeleryTaskStatus taskName={taskName} taskId={taskId} />
        </PageStandard>
    );
}