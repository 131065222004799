import { getAccessToken } from './common'

export async function getRequest(url, callback = null, onError = null) {
    let requestUrl = process.env.REACT_APP_API_URL + url

    const requestOptions = {
        credentials: 'include',
        method: 'GET',
        /*headers: { 
            'Authorization': 'Bearer ' + getAccessToken()
        }*/
    };

    fetch(requestUrl, requestOptions)
        .then(res => res.json())
        .then(
            (result) => {
                if (callback != null) {
                    callback(result);
                }

                console.log(result);

                return result;
            },
            (error) => {
                if (onError != null) {
                    onError(error);
                }
                return error;
            }
        )
}

export async function postRequest(url, data, callback = null) {
    let requestUrl = process.env.REACT_APP_API_URL + url

    const requestOptions = {
        credentials: 'include',
        method: 'POST',
        headers: { 
            'Content-Type': 'application/json',
            //'Authorization': 'Bearer ' + getAccessToken()
        },
        body: JSON.stringify(data)
    };

    fetch(requestUrl, requestOptions)
        .then(response => response.json())
        .then(
            (result) => {
                if (callback != null) {
                    callback(result);
                }

                console.log(result);

                return result;
            },
            (error) => {
                return error;
            }
        )
}

export async function getEventSource(url, callback = null) {
    let requestUrl = process.env.REACT_APP_API_URL + url

    const sse = new EventSource(requestUrl);
    function getRealtimeData(data) {
        console.log(data);
        if (callback !== null) {
            callback(data);
        }
    }
    sse.onmessage = e => getRealtimeData(JSON.parse(e.data));
    sse.onerror = () => {
        // error log here 
        sse.close();
    }
    return () => {
        sse.close();
    };
}