import {
    useLocation
} from "react-router-dom";
import Cookies from 'js-cookie';
import { postRequest } from './fetchers'

export function validateEmail(email) {
    var emailPattern = /^[a-zA-Z0-9.!#$%&'*+/=?^_`{|}~-]+@[a-zA-Z0-9-]+(?:\.[a-zA-Z0-9-]+)*$/;
    if (emailPattern.test(email)) {
        return true;
    }
    return false;
}

export function passwordMinLength(password) {
    if (password.length < 8) {
        return false;
    }

    return true;
}

export function passwordMatches(password, confirmPassword) {
    if (password === confirmPassword) {
        return true;
    }

    return false;
}

export function getCookie(key) {
    return Cookies.get(key);
}

export function logout() {
    postRequest('/api/logout', null, () => reload());
}

export function logoutLocalstorage() {
    localStorage.clear();
    reload();
}

export function getUserInfo(key) {
    return localStorage.getItem(key)
}

export function setUserInfo(userInfo) {
    console.log(userInfo)
    localStorage.setItem(localStorageEnums.email, userInfo.email);
    // More here
}

export function setAccessToken(token) {
    localStorage.setItem(localStorageEnums.accessToken, token);
}

export function getAccessToken() {
    return localStorage.getItem(localStorageEnums.accessToken);
}

export function print(obj) {
    console.log(obj);
}

export function reload(delay = 1000) {
    setTimeout(function () {
        window.location.reload(true);
    }, delay);
}

// A custom hook that builds on useLocation to parse
// the query string for you.
export function useQuery() {
    return new URLSearchParams(useLocation().search);
}

export function setUrlParameters(history, parameter, value) {
    if (value !== null && value !== '') {
        let searchParams = new URLSearchParams(history.location.search);
        searchParams.set(parameter, value);
        history.push({ search: searchParams.toString() });
    } else {
        removeUrlParameter(history, parameter);
    }
}

export function removeUrlParameter(history, parameter) {
    let searchParams = new URLSearchParams(history.location.search);
    searchParams.delete(parameter);
    history.push({ search: searchParams.toString() });
}

export function getUrlKeys() {
    return window.location.pathname.split('/').filter(word => word.length > 0);
}

export function getUrlParameters() {
    let queryDict = {}
    window.location.search.substr(1).split("&").forEach(function (item) { queryDict[item.split("=")[0]] = item.split("=")[1] })
    return queryDict;
}

export function setPageTitle(pageTitle, custom = false) {
    let finalTitle = pageTitle + ' | Content management tool';
    if (custom === true) {
        finalTitle = pageTitle;
    }

    document.title = finalTitle;
}

export function setUrl(title, url) {
    window.history.pushState({}, title, url);
}

export function setUrlParametersOld(title, parameter, value) {
    let urlParams = new URLSearchParams(window.location.search);
    urlParams.set(parameter, value);

    setUrl(title, '?' + urlParams)
}

export function resetUrlParameters(history) {
    history.push({ search: '' });
}

export function resetUrlParametersOld(title) {
    setUrl(title, window.location.href.split('?')[0]);
}

export function removeParameter(title, key) {
    let parameters = getUrlParameters();
    if (parameters.hasOwnProperty(key)) {
        delete parameters[key];
    }

    let queryParameters = '?';
    for (let key in parameters) {
        if (key != null) {
            queryParameters += key + '=' + parameters[key] + '&';
        }
    }

    queryParameters = queryParameters.slice(0, -1);

    setUrl(title, window.location.href.split('?')[0] + queryParameters);
}

export function scrollTop() {
    document.getElementsByTagName('body')[0].scrollIntoView({
        behavior: 'smooth'
    }, 500)
}

export function setVariableIfNotNull(variable, empty = '') {
    if (variable !== null && variable !== '') {
        return variable;
    }

    return empty;
}

export function notEmpty(variable, empty = '') {
    if (variable !== null && variable !== '') {
        return true;
    }

    return false;
}

export const Strings = {
    search: 'search'
}

export const Links = {
    category: '/kategori/',
    aboutUs: '/om-oss/',
    search: '/sok/'
}

export const localStorageEnums = {
    accessToken: 'accessToken',
    email: 'email'
}

export const cookieEnums = {
    activeCategory: 'activeCategory',
    accessToken: 'access_token_cookie',
    activeTab: 'activeTab',
    lastCategory: 'lastCategory',
    lastSubCategory: 'lastSubCategory',
    lastFilter: 'lastFilter'
}