import React, { useEffect } from 'react';
import { useState } from 'react';
import PropTypes from 'prop-types';
import { reload } from '../common';
import { postRequest } from '../fetchers';
import { Button, Checkbox, Input, Select, ExpanderComponent, Form } from './BasicComponents';
import { getRequest } from '../fetchers';

export function CategoryList({ data, ...props }) {
    if (data == null) {
        return <>Category list loading...</>;
    }

    return (
        <>
            {
                data.map((item, i) => {
                    return <CategoryListItem key={i}>{item}</CategoryListItem>
                })
            }
        </>
    );
}

export function CategoryListItem({ ...props }) {
    const data = props.children;

    const baseClass = 'category-list__'

    const buttons = <CategoryButtons data={data} />

    // When we have children
    if (data != null && data.children != null) {
        return (
            <div className={baseClass + 'parent'}>
                <ExpanderComponent expanderText={data.name} extraContent={buttons}>
                    <div className={baseClass + 'child-container'}>
                        <CategoryList data={data.children} />
                    </div>
                </ExpanderComponent>
            </div>
        )
    } else {
        // When there's no children
        return (
            <div className={baseClass + 'child'}>
                <span className={baseClass + 'child-name'}>{data.name}</span>
                {buttons}
            </div>
        )
    }
}

export function CategoryButtons({ data, type, ...props }) {
    console.log(data)
    return (
        <>
            <AddButton dataType={type} dataId={data.id} /> <EditButton dataType={type} dataId={data.id} /> <DeleteButton dataType={type} dataId={data.id} />
        </>
    )
}

export function MessageBar({ ...props }) {
    // Needs to receive a response prop that contains success: bool and message: str
    const [visibility, setVisibility] = useState(false);
    const [timeoutId, setTimeoutId] = useState(null);

    if (props.response) {
        var { success, message } = props.response;
    }

    useEffect(() => {
        setVisibility(true);

        let timeoutId = setTimeout(function () {
            setVisibility(false); // Hide component after 5 seconds
        }, 5000)

        setTimeoutId(timeoutId);
    }, [success, message]);

    // Clear timeout if it's no longer needed
    useEffect(() => {
        return function cleanup() {
            clearTimeout(timeoutId);
        }
    });

    const style = {
        position: 'fixed',
        left: 0,
        right: 0,
        bottom: '33px',
        color: 'white',
        width: '100%',
        background: props.success ? 'green' : 'red',
        textAlign: 'center'
    }

    const visibilityClass = visibility ? 'fade-in' : 'fade-out';

    return (
        <div style={style} className={visibilityClass}>
            {message}
        </div>
    )
}

function TableRow(props) {
    return (
        <div>
            <ExpanderComponent expanderText={<span>{props.children}</span>} extraContent={<span> <EditButton dataType="static_category" dataId={props.data.id} /> <DeleteButton dataId={props.data.id} dataType="static_category" /></span>}>
                <div>&nbsp;Dynamic categories</div>
                {props.data.children.map(function (item, i) {
                    return <div key={i}>
                        &nbsp;&nbsp;{item.name} | <DeleteButton dataId={item.id} dataType="dynamic_category" />
                    </div>
                })}
            </ExpanderComponent>
            <hr />
        </div>
    )
}

function AddButton({ dataId, dataType, ...props }) {
    const [isEditable, setEditable] = useState(false);
    const [value, setValue] = useState(null);

    function add() {
        postRequest('/api/save_category', { id: dataId, type: dataType, name: value }, reload());
        setEditable(false);
    }

    function cancel() {
        setEditable(false);
    }

    if (isEditable) {
        return (
            <span>
                <Input onChange={(e) => setValue(e.target.value)} autoFocus={true} onEscape={cancel} onEnter={add} placeholder="New sub category"></Input> <Button onClick={add}>Add</Button> <Button onClick={cancel}>Cancel</Button>
            </span>
        )
    } else {
        return (
            <Button onClick={() => setEditable(!isEditable)}>Add</Button>
        )
    }
}

function EditButton({ dataId, dataType, ...props }) {
    const [isEditable, setEditable] = useState(false);
    const [value, setValue] = useState(null);

    function update() {
        postRequest('/api/update_category', { id: dataId, type: dataType, name: value }, reload());
        setEditable(false);
    }

    function cancel() {
        setEditable(false);
    }

    if (isEditable) {
        return (
            <span>
                <Input onChange={(e) => setValue(e.target.value)} autoFocus={true} onEscape={cancel} onEnter={update} placeholder="New name"></Input> <Button onClick={update}>Update</Button> <Button onClick={cancel}>Cancel</Button>
            </span>
        )
    } else {
        return (
            <Button onClick={() => setEditable(!isEditable)}>Edit</Button>
        )
    }
}

export function ConfirmButton({ onClick, confirmText, ...props }) {
    const text = confirmText ? confirmText : 'Please confirm.';

    function handleClick() {
        if (onClick) {
            var r = window.confirm(text);
            if (r == true) {
                onClick();
            }
        }
    }

    return (
        <Button {...props} onClick={handleClick}>{props.children}</Button>
    )
}

export function DeleteButton({ dataId, dataType, ...props }) {
    function deleteRow() {
        postRequest('/api/delete_category', { id: dataId, type: dataType }, reload());
    }

    return (
        <ConfirmButton {...props} confirmText="Confirm you want to delete?" onClick={deleteRow}>Delete</ConfirmButton>
    )
}

export function SavedCategoriesTable(props) {
    const [showAll, setShowAll] = useState(false);
    const [newCategory, setNewCategory] = useState(false);

    function saveData() {
        postRequest('/api/save_category', { type: 'static_category', name: newCategory }, reload());
    }

    const result = props.data.map(function (item, i) {
        return <TableRow show={showAll} key={i} data={item}>{item.name}</TableRow>
    });

    return (
        <div>
            {props.children}
            <hr />
            <Input onChange={(e) => setNewCategory(e.target.value)} onEnter={saveData} type="text" placeholder="New category name"></Input> <Button onClick={saveData}>Create static category</Button>
            <br /><br />
            <Button onClick={() => setShowAll(!showAll)}>Show/hide all</Button> <br /><br />
            {result}
        </div>
    )
}

export function CategorySelect({ data, onChange, value, defaultOptionText, ...props }) {
    const defaultText = defaultOptionText != undefined ? defaultOptionText : 'Select category';
    const defaultOptions = [<option key="0" value="">{defaultText}</option>];
    const options = data.map(function (item, i) {
        return <option key={i} value={item.id}>{item.name}</option>
    })

    return (
        <Select {...props} value={value} onChange={onChange}>{defaultOptions}{options}</Select>
    )
}

export function Checklist({ filter, markAll, ...props }) {
    // keys prop must be provided with a dictionary so that the correct values are mapped to the props
    // checkboxTemplate prop must be provided with a functional component to fill the label of the checkbox
    const [values, setValues] = useState(new Array(props.data.length).fill(null));

    function updateStores(array) {
        setValues(array);
        props.setter(array.filter(element => element !== null && element !== undefined));
    }

    function handleClick(index, item, e) {
        //console.log(values);
        //console.log(e);

        if (e.target.checked) { // Add
            let array = [...values];
            array[index] = item;
            updateStores(array)
        } else { // Remove
            let array = [...values];
            array[index] = null;
            updateStores(array);
        }
    }

    var result = props.data.map(function (item, i) {
        let visible = false;
        if (!filter || filter === '' || (item.category + ' ' + item.sub_category).includes(filter)) {
            visible = true;
        }

        let checked = false;
        if (visible === true && markAll === true) {
            checked = true;
        }

        return (
            <div value={item} style={{ display: visible ? 'block' : 'none' }} key={i}>
                <Checkbox defaultChecked={checked} value={item[props.valueKey]} onClick={(event) => handleClick(i, item, event)} checkboxTemplate={() => props.checkboxTemplate(item)} />
            </div>
        )
    })

    if (result.length === 0) {
        result = 'Nothing more to check, good job.';
    }

    useEffect(() => {
        if (markAll == true) {
            let markedElements = []
            result.forEach(element => {
                if (element.props.style.display === 'block') {
                    markedElements.push(element.props.value);
                }
            });

            updateStores(markedElements);
        } else if (markAll == false) {
            updateStores([]);
        }
    }, [markAll])

    return (
        <div className={props.className}>
            {result}
        </div>
    )
}

Checklist.propTypes = {
    keys: PropTypes.object,
    template: PropTypes.func,
};