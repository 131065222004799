import React from 'react';
import { BrowserRouter as Router, Switch, Route } from "react-router-dom";
import { CategoryTreePage } from './pages/CategoryTreePage';
import { CategoryMappingPage } from './pages/CategoryMappingPage';
import { OopsPage } from './pages/OopsPage';
import { UsersPage } from './pages/UsersPage';
import { ProfilePage } from './pages/ProfilePage';
import { RedisPage } from './pages/RedisPage';
import { CiPage } from './pages/CiPage';

// List of our routes, it is used to create routes for the app and the navigation. 
// If an object has the text attribute, it will be shown in navigation
// Order in list will determine order in navigation
export const routeList = [
    { url: '/', component: <CiPage />, text: 'CI' },
    { url: '/category-tree', component: <CategoryTreePage />, text: 'Category tree management' },
    { url: '/category-mapping', component: <CategoryMappingPage />, text: 'Category mapping' },
    { url: '/users', component: <UsersPage />, text: 'User management' },
    { url: '/profile', component: <ProfilePage />, text: 'My profile' },
    { url: '/redis', component: <RedisPage />, text: 'Redis management' },
]

export function getRoutes() {
    const routes = routeList.map((route, i) => {
        return (
            <Route key={i} exact path={route.url}>
                {route.component}
            </Route>
        )
    });

    return routes;
}

// Used in app
export function Routes() {
    return (
        <Router>
            <Switch>
                {getRoutes()}
                <Route>
                    <OopsPage />
                </Route>
            </Switch>
        </Router>
    )
}

// Used when creating sitemap
export const SitemapRouter = (
    <Switch>
        {getRoutes()}
    </Switch >
)