import React, { useEffect } from 'react';
import { useState } from 'react';
import { CategorySelect, DeleteButton, ConfirmButton } from './DataComponents.js';
import { reload } from '../common';
import { postRequest } from '../fetchers';
import { Checklist } from './DataComponents';
import { Button, Checkbox, Input, Select, ExpanderComponent } from './BasicComponents';
import { getRequest } from '../fetchers';
import { CategorizationConstants } from '../constants';
import { cookieEnums } from '../common';
import { useCookies } from 'react-cookie'

export function AutoMapping({ ...props }) {
    const [selectedSuggestions, setSelectedSuggestions] = useState([]);
    const [suggestions, setSuggestions] = useState([]);
    const [markAll, setMarked] = useState(false);

    function CheckboxTemplate(item) {
        return <span>{item.uncategorized.category} - {item.uncategorized.sub_category} {'->'} {item.category.name} - {item.sub_category.name}</span>
    }

    function getSuggestions() {
        getRequest('/api/get_uncategorized_pair_suggestions', (result) => setSuggestions(result));
    }

    function save() {
        if (selectedSuggestions.length > 0) {
            console.log(selectedSuggestions)
            postRequest('/api/save_category', { type: CategorizationConstants.SUGGESTION_LIST, suggestions: selectedSuggestions }, reload());
        } else {
            alert('Select a pair first');
        }
    }

    return (
        <>
            <Button onClick={() => getSuggestions()}>Get suggestions</Button>
            &ensp;
            <Checkbox defaultChecked={markAll} onClick={() => setMarked(!markAll)} checkboxTemplate={() => 'Mark all'} />
            &ensp;
            <ConfirmButton onClick={save}>Save suggestions</ConfirmButton>
            <br />
            <h3>Suggestions</h3>
            <Checklist markAll={markAll} checkboxTemplate={CheckboxTemplate} valueKey="id" data={suggestions} setter={setSelectedSuggestions} />
        </>
    )
}

export function SubCategoryMapping({ staticCategories, uncategorized, ...props }) {
    const [cookies, _setCookie] = useCookies([cookieEnums.lastCategory, cookieEnums.lastSubCategory])
    const previousCategory = cookies.lastCategory != null ? cookies.lastCategory : 0;
    const previousSubCategory = cookies.lastSubCategory != null ? cookies.lastSubCategory : 0;
    const previousFilter = cookies.lastFilter != null ? cookies.lastFilter : '';
    
    const [selectedDynamicCategories, setSelectedDynamicCategories] = useState([]);
    const [selectedCategory, setCategory] = useState(previousCategory);
    const [selectedSubCategory, setSubcategory] = useState(previousSubCategory);
    const [optionValues, setOptionValues] = useState([]);
    const [checklistFilter, _setFilter] = useState(previousFilter);
    const [markAll, setMarked] = useState(false);

    function setCookie(cookieKey, cookieValue) {
        let expires = new Date()
        expires.setTime(expires.getTime() + (1000000000000))
        _setCookie(cookieKey, cookieValue, { path: '/', expires })
    }

    function CheckboxTemplate(item) {
        return <span>{item.category} - {item.sub_category}</span>
    }

    function save() {
        //console.log(selectedDynamicCategories)
        //console.log(selectedCategory)
        //console.log(selectedSubCategory)
        if (selectedDynamicCategories.length > 0) {
            postRequest('/api/save_category', { type: CategorizationConstants.DYNAMIC_SUB_CATEGORY, categories: selectedDynamicCategories, category_id: selectedCategory, sub_category_id: selectedSubCategory }, reload());
        } else {
            alert('Select a pair first');
        }
    }

    function createSubCategoryOptions(value) {
        let options = [];

        // Choose correct options for select by matching value to category id
        staticCategories.forEach(element => {
            if (element.id == value) {
                options = element.children;
                return;
            }
        });

        setOptionValues(options);
    }

    function onCategoryChange(value) {
        setCategory(value);
        setCookie(cookieEnums.lastCategory, value)
        createSubCategoryOptions(value);
    }

    function onSubCategoryChange(value) {
        setSubcategory(value);
        setCookie(cookieEnums.lastSubCategory, value);
    }

    function setFilter(value) {
        _setFilter(value);
        setCookie(cookieEnums.lastFilter, value);
    }

    function handleChecklistChange(values) {
        const selection = values.map((value) => {
            //console.log(value)
            return value.category + '{||||}' + value.sub_category;
        })

        setSelectedDynamicCategories(selection);
    }

    // Preselect option based on stored cookie value
    useEffect(() => {
        createSubCategoryOptions(selectedCategory);
    }, [staticCategories])

    return (
        <>
            <CategorySelect value={selectedCategory} onChange={e => onCategoryChange(e.target.value)} data={staticCategories} />
            &nbsp;
            <CategorySelect value={selectedSubCategory} onChange={e => onSubCategoryChange(e.target.value)} data={optionValues} defaultOptionText="Select sub category" /> 
            &nbsp;
            <Button onClick={save}>Save</Button>
            <br />
            <Input onEscape={() => setFilter('')} value={checklistFilter} onEnter={() => setMarked(!markAll)} placeholder="Filter results" onChange={e => setFilter(e.target.value)} /> <Checkbox defaultChecked={markAll} onClick={() => setMarked(!markAll)} checkboxTemplate={() => 'Mark all'} />
            <br />
            <br />
            <Checklist markAll={markAll} filter={checklistFilter} checkboxTemplate={CheckboxTemplate} valueKey="id" data={uncategorized} setter={handleChecklistChange} />
        </>
    )
}

export function MappingList({ data, ...props }) {
    return (
        data.map((item, i) => {
            return (
                <ExpanderComponent key={i} expanderText={item.name}>
                    <div>&emsp;<strong>Dynamic categories</strong></div>
                    {item.dynamic_children.map((child, j) => {
                        return <div key={j}>&emsp;&emsp;{child.category} <DeleteButton dataType={CategorizationConstants.DYNAMIC_CATEGORY} dataId={child.id} /></div>
                    })}
                    <div>&emsp;<strong>Static sub categories</strong></div>
                    {item.children.map((child, j) => {
                        const name = <span>&emsp;&emsp;{child.name}</span>
                        return (
                            <ExpanderComponent key={j} expanderText={name}>
                                {child.dynamic_children.map((d_child, k) => {
                                    const d_name = <span>&emsp;&emsp;&emsp;{d_child.category} - {d_child.sub_category}</span>
                                    return <div key={k}>{d_name} <DeleteButton dataType={CategorizationConstants.DYNAMIC_SUB_CATEGORY} dataId={d_child.id} /></div>
                                })}
                            </ExpanderComponent>
                        )
                    })}
                </ExpanderComponent>
            )
        })
    )
}

export function CategoryMapping({ staticCategories, ...props }) {
    const [dynamicCategories, setDynamicCategories] = useState([]);
    const [selectedDynamicCategories, setSelectedDynamicCategories] = useState([]);
    const [selectedStaticCategory, setSelectedStaticCategory] = useState([]);

    function CheckboxTemplate(item) {
        return <span>{item.category}</span>
    }

    function handleSelection(values) {
        const selection = values.map((value) => {
            return value.category;
        })

        setSelectedDynamicCategories(selection);
    }

    function save() {
        //console.log(selectedStaticCategory)
        postRequest('/api/save_category', { type: CategorizationConstants.DYNAMIC_CATEGORY, categories: selectedDynamicCategories, parent_id: selectedStaticCategory }, reload());
    }

    useEffect(() => {
        getRequest('/api/get_uncategorized_categories', setDynamicCategories);
    }, [])

    return (
        <>
            <CategorySelect onChange={e => setSelectedStaticCategory(e.target.value)} data={staticCategories} /> <Button onClick={save}>Save</Button>
            <br />
            <br />
            <Checklist checkboxTemplate={CheckboxTemplate} valueKey="id" data={dynamicCategories} setter={handleSelection} />
        </>
    )
}