import React from 'react';
import { Header } from './HeaderComponent';
import { Link } from 'react-router-dom';
import { setPageTitle, getUrlKeys, Links } from '../common'

export function Heading(props) {
    return (
        <h1 className="standard-heading">
            {props.children}
        </h1>
    );
}

export function CenterContent(props) {
    return (
        <div className="centered-div">
            {props.children}
        </div>
    );
}

export function Page(props) {
    return (
        <div id="page-container">
            {props.children}
        </div>
    );
}

export function WideWrapper({ ...props }) {
    const baseClass = 'components-wide-container__root';

    return (
        <div className={baseClass} {...props}>
            {props.children}
        </div>
    );
}

export function PageStandard(props) {
    return (
        <Page>
            <Header showHeader={true} />
            <Wrapper>
                <PageFiller>
                    {props.children}
                </PageFiller>
            </Wrapper>
            <Footer noMargin />
        </Page>
    );
}

export function HeaderlessPage(props) {
    return (
        <Page>
            <Wrapper>
                <PageFiller>
                    {props.children}
                </PageFiller>
            </Wrapper>
            <Footer noMargin={true} />
        </Page>
    );
}

export function PageStaticContent(props) {
    return (
        <Page>
            <Header />
            <Wrapper>
                <PageFiller>
                    {props.children}
                </PageFiller>
            </Wrapper>
            <Footer noMargin={true} />
        </Page>
    );
}

export function TopPadding(props) {
    return (
        <div className="top-padding">
            {props.children}
        </div>
    );
}

export function Wrapper(props) {
    let classes = 'container';
    if (props.verticalPadding === true) {
        classes = 'container top-padding';
    }

    return (
        <div className={classes}>
            {props.children}
        </div>
    );
}

export function PageFiller(props) {
    return (
        <div className="page-filler">
            {props.children}
        </div>
    );
}

export function PageHeading(props) {
    const url_keys = window.APP_DATA.url_keys;

    let urlParameters = getUrlKeys();

    if (urlParameters.length === 0) {
        return '';
    }

    if (props.isSearch) {
        let pageTitle = decodeURI(urlParameters[1]);
        setPageTitle(pageTitle);

        return (
            <div id="searchHeading">
                <h1 className="centered-heading">Sökresultat för: <Link to={Links.search + urlParameters[1]}>{pageTitle}</Link></h1>
            </div>
        )
    }

    let category = <Link to={Links.category + urlParameters[1]}>{url_keys[urlParameters[1]].name}</Link>
    let subCategory = '';
    let pageTitle = url_keys[urlParameters[1]].name;

    if (urlParameters.length > 2) {
        subCategory = <Link to={Links.category + urlParameters[1] + '/' + urlParameters[2]}>{url_keys[urlParameters[2]].name}</Link>
        pageTitle = url_keys[urlParameters[2]].name;
    }

    setPageTitle(pageTitle);

    return (
        <div id="breadcrumbs">
            <h1 className="centered-heading">{category} {subCategory !== '' ? ' > ' : ''} {subCategory}</h1>
        </div>
    );
}

export function Footer(props) {
    let style = {};
    if (props.noMargin) {
        style.marginBottom = 0;
    }

    return (
        <div style={style} id="footer">
            &copy; Content management tool
        </div>
    );
}