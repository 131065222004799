import { useState, useEffect } from 'react';
import { PageStandard } from '../components/PageComponents.js';
import { getRequest, postRequest } from '../fetchers';
import { CategoryList, AddNewCategory } from '../components/CategoryTreeComponents';
import { setPageTitle, reload } from "../common.js";

export function CategoryTreePage() {
    setPageTitle('Category tree');

    const [staticCategories, setStaticCategories] = useState(null);

    useEffect(() => {
        getRequest('/api/get_static_categories', setStaticCategories)
    }, [])

    return (
        <PageStandard>
            <AddNewCategory />
            <CategoryList data={staticCategories}/>
        </PageStandard>
    );
}